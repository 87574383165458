import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import PageLayout from '../components/common/page-layout/page-layout';
import SectionIntended from '../components/templates/section-intended/section-intended';

import NewsletterStyles from './_scss/newsletter.module.scss';

const NewsletterPage = () => {
    const intl = useIntl();
    const [pageParam] = React.useState(
        typeof window !== 'undefined' && document.location.search ? document.location.search.substring(1) : '',
    );
    const validPageParams = ['subscribe', 'subscribed', 'unsubscribe', 'blocked'];
    return (
        <PageLayout title={intl.formatMessage({ id: 'pages.newsletter.title' })}>
            {validPageParams.includes(pageParam) && (
                <SectionIntended>
                    <div className={NewsletterStyles.newsletter}>
                        <h3 className="color-white">
                            <FormattedMessage id={'components.newsletter.' + pageParam + '.headline'} />
                        </h3>
                        <p className="color-white">
                            <FormattedMessage id={'components.newsletter.' + pageParam + '.copytext'} />
                        </p>
                    </div>
                </SectionIntended>
            )}
        </PageLayout>
    );
};

export default NewsletterPage;
